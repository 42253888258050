import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: ''
	, variant: 'packet'
	, owner: 'backatyou'
	, status: 'draft'
	, history: []
	, user: null
	, recipients: []
	, transaction: null
	, fields: []
};

export const packetSlice = createSlice({
	name: 'packet',
	initialState,
	reducers: {
		setPacketState: (state, action) => {
			//null is not a valid
			if(!action.payload){
				return initialState;
			}else{
				return {...state, ...action.payload};
			}
		},
		resetPacketState: (state, action) => {
			return initialState;
			// console.log('Project Remove', action.payload)
		}
	}
});

// Action creators are generated for each case reducer function
export const { setPacketState, resetPacketState } = packetSlice.actions;

export const getPacketState = (state) => state.packet
export const getDefaultPacketState = (state) => initialState

export default packetSlice.reducer;