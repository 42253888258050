import { combineReducers } from 'redux'

import packet from '../slices/packetSlice'
import user from '../slices/userSlice'
import actingAs from '../slices/actingAsSlice'

export default combineReducers({
	packet,
	user,
	actingAs
});
