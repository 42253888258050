import axios from "axios";
import Cookies from 'js-cookie';

import files from './apis/files';
import login from './apis/login';
import packets from './apis/packets';

axios.interceptors.request.use(function (config) {
	// console.log('interceptors.request', config);
	const bayToken = Cookies.get('bayToken');

	if(bayToken) config.headers.Authorization = `Bearer ${bayToken}`

	return config;
}, function (error) {
	return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
	// console.log('interceptors.response', response);

	if(response.data && response.data.meta && response.data.meta.bayToken) Cookies.set('bayToken', response.data.meta.bayToken);

	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	// //remove the cookie and redirect to login page if the token is invalid
	if(error.response.status === 401){
		sessionStorage.clear();
		Cookies.remove('bayToken');
		window.location.href = '/';
	}

	// console.log(error)

	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});

export default {
	files
	, login
	, packets
};
