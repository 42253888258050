import React, { useEffect, useState, Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { isEmpty, isNil } from 'lodash';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

import globalState from '../state/global';

import api from "../modules/api";

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const Packet = React.lazy(() => import("./pages/packet"));
const Index = React.lazy(() => import("./pages/index"));

export const App = () => {
	const userState = globalState.useUserState();
	const actingAsState = globalState.useActingAsState();

	const globalDispatch = globalState.useDispatch();

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route errorElement={<div>Error</div>} path="/" element={<Suspense fallback={<div>Loading</div>}><Index /></Suspense>} />
				<Route>
					<Route errorElement={<div>Error</div>} path="/packet" element={<Suspense fallback={<div>Loading</div>}><Packet /></Suspense>} />
					<Route errorElement={<div>Error</div>} path="/packet/:action/:step" element={<Suspense fallback={<div>Loading</div>}><Packet /></Suspense>} />
				</Route>

			</>
		)
	);

	return (
		<RouterProvider router={router} />
	);
};

export default App;