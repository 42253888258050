import _ from 'lodash';

export const colorToRGBA = (input) => {
	// console.log('colorToRGBA', 'Before', input);

	let m, r, g, b, a = 1;

	if (input.indexOf('rgba') !== -1) {
		m = input.match(/^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/i);

		if(!m) return;

		r = _.parseInt(m[1]);
		g = _.parseInt(m[2]);
		b = _.parseInt(m[3]);
		a = parseFloat(m[4]);
	} else if (input.indexOf('rgb') !== -1) {
		m = input.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);

		if(!m) return;

		r = _.parseInt(m[1]);
		g = _.parseInt(m[2]);
		b = _.parseInt(m[3]);
	} else if (input.indexOf('#') !== -1) {
		input = input.replace('#', '');

		r = _.parseInt(input.slice(0, 2), 16);
		g = _.parseInt(input.slice(2, 4), 16);
		b = _.parseInt(input.slice(4, 6), 16);
	} else {
		// console.log('colorToRGBA', 'After', input);
		return input;
	}

	return {r, g, b, a, string: `rgba(${r}, ${g}, ${b}, ${a})`};
};

export const classNameToColor = (color) => {
	let colorElem = document.getElementsByClassName(`bg-${color}`);

	if(!colorElem || colorElem.length === 0){
		let newElm = document.createElement('i');
		newElm.className = `bg-${color}`;

		document.body.appendChild(newElm);

		colorElem = document.getElementsByClassName(`bg-${color}`)
	}

	return getComputedStyle(colorElem[0]).backgroundColor;
};

export const formatPhoneNumber = (phoneNumberString) => {
	let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		let intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return null;
};

export const getSessionState = (stateName) => {
	try {
		const persistedState = window.sessionStorage.getItem(stateName);

		if (persistedState) {
			return JSON.parse(persistedState);
		} else {
			return;
		}
	} catch (e) {
		console.log(e);
	}
};

export const setSessionState = (stateName, state) => {
	try {
		return window.sessionStorage.setItem(stateName, JSON.stringify(state));
	} catch (e) {
		console.log(e);
	}
};
