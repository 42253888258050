import axios from 'axios';
import { isNil } from 'lodash';
import { ACCOUNT_API_URL } from '../../lib/environment';
import { getAccess } from '../access';

export default {
	login : async (opts) => {
		return new Promise((resolve, reject)=>{
			let {email, password} = opts;

			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				'url': `${ACCOUNT_API_URL}/sessions`,
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
				data: {
					"username": email
					, "password": password
				},
				signal: controller.signal
			})
			.then(resp=>{
				clearTimeout(timeout);

				let access;

				if(!isNil(resp.data.result.user)){
					access = getAccess(resp.data.result.user);
				}

				if(!access || !access.canLogin){
					reject({status:1, message:`User account is not authorized to login to this application. Please contact an administrator to grant this user account loging access.`});
				}else{
					resolve(resp.data);
				}
			})
			.catch(err=>{
				if(err.response?.status === 401){
					resolve({status:1, message:'Invalid Email or Password'});
					return;
				}

				clearTimeout(timeout);

				reject({status:1, message:err.message});
			});
		});
	},
	refresh : async () => {
		return new Promise((resolve, reject)=>{
			const controller = new AbortController();

			let timeout = setTimeout(()=>{
				controller.abort();
			}, 180000);

			axios({
				'url': `${ACCOUNT_API_URL}/sessions/current`,
				method: 'GET',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
				signal: controller.signal
			})
			.then(resp=>{
				clearTimeout(timeout);

				let access;

				if(!isNil(resp.data.result.user)){
					access = getAccess(resp.data.result.user);
				}

				if(!access || !access.canLogin){
					reject({status:1, message:`User account is not authorized to login to this application. Please contact an administrator to grant this user account loging access.`});
				}else{
					resolve(resp.data);
				}
			})
			.catch(err=>{
				clearTimeout(timeout);

				reject({status:1, message:err.message});
			});
		});
	}
}
