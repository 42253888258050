import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import App from './components/App';

import globalState from './state/global';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Provider store={globalState.store}>
		<App />
	</Provider>
);
