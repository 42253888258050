import { configureStore } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux'

import { getSessionState, setSessionState } from '../modules/utils';

import { getPacketState, setPacketState, resetPacketState, getDefaultPacketState } from './slices/packetSlice';
import { getUserState, setUserState, resetUserState } from './slices/userSlice';
import { getActingAsState, setActingAsState, resetActingAsState } from './slices/actingAsSlice';

import reducers from './reducers';

const preloadedState = getSessionState('globalState');

const store = configureStore({
	devTools: true,
	reducer: reducers,
	preloadedState: preloadedState
});

store.subscribe(()=>{
	setSessionState('globalState', store.getState());
});

const globalState = {
	//redux
	useDispatch
	, store
	//packet
	, usePacketState: ()=>{
		let packetState = useSelector(getPacketState);

		return packetState;
	}
	, getDefaultPacketState
	, setPacketState
	, resetPacketState

	//user
	, useUserState: ()=>{
		let userState = useSelector(getUserState);

		return userState;
	}
	, setUserState
	, resetUserState

	//Acting As
	, useActingAsState: ()=>{
		let actingAsState = useSelector(getActingAsState);

		return actingAsState;
	}
	, setActingAsState
	, resetActingAsState
}

export default globalState;